
.page-bg {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.page-home {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
@media (min-width: 768px) {
  .page-home {
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: bottom;
  }
}